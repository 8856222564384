<template>
  <b-container>
    <POSVenta></POSVenta>
  </b-container>
</template>

<script>
import POSVenta from "@/components/layouts/POSVenta";
export default {
  components: { POSVenta },
};
</script>

<style>
</style>