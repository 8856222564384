<template>
  <b-overlay :show="cargando > 0" rounded="sm">
    <b-container>
      <b-row>
        <b-form-group class="w-100" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            class="btn btn-block"
            v-model="selected"
            :options="mediosPago"
            text-field="nombre"
            value-field="id"
            button-variant="outline-success"
            :aria-describedby="ariaDescribedby"
            size="sm"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-row>
      <!-- CALCULADORA DE VUELTOS -->
      <b-row v-if="selected === 1" class="mb-3">
        <b-col sm="12" lg="8">
          <b-row cols="12">
            <b-col
              v-for="denominacion in denominaciones"
              :key="denominacion.monto"
              cols="4"
            >
              <b-button
                block
                @click="sumarAPagado(denominacion.monto)"
                class="btn-sexduceme"
                ><small>{{ formatPrice(denominacion.monto) }}</small></b-button
              >
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" lg="4">
          <b-row>
            <b-col sm="3" md="3" lg="12">
              Venta: {{ formatPrice(totalPagado) }}
            </b-col>
            <b-col sm="3" md="3" lg="12">
              Pagado: {{ formatPrice(totalCompra) }}
            </b-col>
            <b-col sm="3" md="3" lg="12">
              Vuelto: {{ formatPrice(totalPagado - totalCompra) }}
            </b-col>
            <b-col>
              <b-button @click="resetCalculadora()" block variant="danger"
                ><b-icon icon="x-octagon"></b-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
const axios = require("@/assets/functions/axiosClient");
const { formatPrice } = require("@/assets/functions/formaters");
export default {
  props: ["totalCompra"],
  watch: {
    selected: function (newValue) {
      this.$emit("selected", newValue);
    },
  },
  data() {
    return {
      cargando: 0,
      selected: 1,
      mediosPago: [],
      totalPagado: 0,
      denominaciones: [
        {
          nombre: "billete-20000",
          monto: 20000,
          imagen: "",
        },
        {
          nombre: "billete-10000",
          monto: 10000,
          imagen: "",
        },
        {
          nombre: "billete-5000",
          monto: 5000,
          imagen: "",
        },
        {
          nombre: "billete-2000",
          monto: 2000,
          imagen: "",
        },
        {
          nombre: "billete-1000",
          monto: 1000,
          imagen: "",
        },
        {
          nombre: "moneda-500",
          monto: 500,
          imagen: "",
        },
        {
          nombre: "moneda-100",
          monto: 100,
          imagen: "",
        },
        {
          nombre: "moneda-50",
          monto: 50,
          imagen: "",
        },
        {
          nombre: "moneda-10",
          monto: 10,
          imagen: "",
        },
      ],
    };
  },
  methods: {
    formatPrice,
    resetCalculadora() {
      this.totalPagado = 0;
    },
    getMediosHabilitados() {
      this.cargando++;
      axios
        .get("/medios-pago/habilitados")
        .then((response) => {
          if (response.status === 200) {
            this.mediosPago = response.data.data;
          } else if (response.status === 204) {
            console.log("No hay medios de pagos habilitados");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    sumarAPagado(monto) {
      this.totalPagado += monto;
    },
  },
  created() {
    this.getMediosHabilitados();
  },
};
</script>

<style>
</style>