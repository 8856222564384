function showMessage(context, title, content, variant, solid = true) {
    console.log("Shower Toast")
    context.$bvToast.toast(content, {
        "auto-hide-delay": 5000,
        title,
        variant,
        solid,
    })
}

module.exports = {
    showError(context, title, content) {
        showMessage(context, '[ERROR] ' + title, content, 'danger', true);
    },

    showWarning(context, title, content) {
        showMessage(context, '[ADVERTENCIA] ' + title, content, 'warning', true);
    },

    showSuccess(context, title, content) {
        showMessage(context, '[ÉXITO] ' + title, content, 'success', true);
    }
}