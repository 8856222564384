import Carousel from '@/components/Carousel.vue';
<template>
<div class="container">
    <div class="fondo-rosa row vertical-centro" :class="producto.stock<1 ? 'rosa-trans':''" >
        <Carousel class="col-4 col-md-2" :imagenes="producto.imagenes" :id="producto.id"></Carousel>
        <h5 @click="verMas(producto.nombre,producto.codigo)" class="alert alert-danger col-5 col-md-4 vertical-centro">{{producto.nombre}}</h5>
        <div class="col-3 col-md-4">
            <p class="card-text precio " :class="producto.descuento>0 ? 'tachado' : ''">{{formatPrice(producto.precio)}}</p>
            <p class="card-text precio " v-if="producto.descuento>0">
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-star-fill fill-gold" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
                {{formatPrice(aplicarDescuento(producto.precio,producto.descuento))}}
            </p>
            
        </div>
        <!-- Botonera de Producto -->
            <div class="botonera-producto row col-12 col-md-2">
                <!-- AGREGAR AL CARRO -->
                <a class="item-botonera col-12" target="blank" data-v-7b876761="">
                    <button :id="'agregarSimple-'+this.producto.id" class="btn btn-carro btn-block sombrear" :class="!producto.stock>0 ? 'desaparecer':''" @click="agregarCarro()" data-v-7b876761="">
                    <i class="fa fa-cart-plus"></i> 
                    </button>
                </a>
            </div>
    </div>
</div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
    name: "SimpleCardProducto",
    props:["producto"],
    components:{
        Carousel
    },
    methods:{
        aplicarDescuento(precio,descuento){
            let precioDescuento = precio-(precio*(descuento/100));
            return precioDescuento-(precioDescuento%10);
        },
        formatPrice(price){
            // return price;
            let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
            return '$ ' + priceString.substring(0,priceString.length-2);
        },
        verMas(
            ){
            this.$emit('seleccionado',this.producto.codigo)
        },
        agregarCarro(){
            let productoAdd = {
                id: this.producto.id,
                nombre: this.producto.nombre,
                imagen: this.producto.imagenes[0].url,
                precio: this.aplicarDescuento(this.producto.precio,this.producto.descuento),
                codigo: this.producto.codigo,
                cantidad: 1
            }
            let carroLocal = JSON.parse(localStorage.getItem('carrito'));
            if(carroLocal===null)
                carroLocal = [];
            let esta = false
            carroLocal.forEach(prod => {
                if(prod.id===productoAdd.id){
                    prod.cantidad++;
                    esta = true;
                    return true;
                }
            });
            if(!esta)
                carroLocal.push(productoAdd);
            localStorage.setItem('carrito',JSON.stringify(carroLocal));
            document.getElementById('agregarSimple-'+productoAdd.id).innerHTML=
            "<p>Producto Agregado al Carrito</p>";
            setTimeout(function(){
                document.getElementById('agregarSimple-'+productoAdd.id).innerHTML=
                '<i class="fa fa-cart-plus"></i>';
            }, 2000);
        },
    }
}
</script>

<style>
    .vertical-centro{
        vertical-align: middle!important;
    }
</style>