<template>
  <div class="container">
    <h1 class="col-12 my-3">POS de Venta:</h1>
    <!-- DATOS DEL CLIENTE -->
    <div class="row">
      <div id="div_datos_cliente" class="row">
        <h2 class="col-12 text-left">Datos del Cliente:</h2>
        <div
          v-if="!modoFeria"
          id="div_nombre"
          class="input-group col-sm-12 col-md-4"
        >
          <div class="input-group-prepend">
            <span class="input-group-text" id="input-nombre">Nombre:</span>
          </div>
          <input v-model="venta.cliente" type="text" class="form-control" />
        </div>
        <div
          v-if="!modoFeria"
          id="div_direccion"
          class="input-group col-sm-12 col-md-4"
        >
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Direccion:</span>
          </div>
          <input v-model="venta.direccion" type="text" class="form-control" />
        </div>
        <div id="div_telefono" class="input-group col-sm-12 col-md-4">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Teléfono:</span>
          </div>
          <input
            v-model="venta.telefono"
            type="text"
            class="form-control"
            maxlength="11"
          />
        </div>
        <div
          v-if="!modoFeria"
          id="div_estado"
          class="
            form-group
            d-flex
            justify-content-center
            px-0
            my-1
            col-sm-12 col-md-4
          "
        >
          <div class="input-group-prepend">
            <span class="input-group-text">Estado:</span>
          </div>
          <select v-model="venta.estadoId" class="form-control">
            <option
              v-for="estado in estados"
              :key="estado.id"
              :value="estado.id"
            >
              {{ estado.nombre }}
            </option>
          </select>
        </div>
        <div
          v-if="!modoFeria"
          id="div_fecha"
          class="form-group d-flex justify-content-center col-sm-12 col-md-4"
        >
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Fecha de Compra:</span
            >
          </div>
          <div class="row">
            <input
              v-model="venta.fechaCompra"
              @change="mostrarVenta()"
              class="form-control col-10"
              type="datetime-local"
              id="example-date-input"
            />
            <button @click="setNow()" class="btn btn-success col-2">
              <i class="fa fa-clock-o"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- INPUT DE DETALLE -->
      <div class="col-12">
        <textarea
          v-model="inputMasivo"
          rows="10"
          placeholder="Ingrese aquí el mensaje de compra"
          class="boxsizingBorder w-100"
        >
        </textarea>
        <button class="p-2 btn-sexduceme btn-block seleccionable" @click="procesarMasivo()">Procesar</button>
      </div>
      <!-- PRODUCTOS COMPRADOS -->
      <h2 class="col-12 text-left">Productos de la Compra:</h2>
      <div class="row col">
        <div class="row col">
          <div
            class="
              form-group
              row
              col-sm-12 col-md
              d-flex
              justify-content-center
            "
          >
            <div class="input-group-prepend col-4">
              <span class="input-group-text" id="basic-addon1">Buscar:</span>
            </div>
            <div class="col-8 px-0">
              <!-- <input id="buscador" @blur="buscarCodigo(busqueda)" v-model="busqueda" type="text" class="form-control"> -->
              <!-- <textarea id="buscador" @blur="buscarCodigo(busqueda)" v-model="busqueda" class="form-control"></textarea> -->
              <div class="form-group row">
                <textarea
                  class="form-control"
                  @focus="limpiarProducto()"
                  @blur="buscarCodigo(busqueda)"
                  v-model="busqueda"
                  id="buscador"
                  rows="1"
                ></textarea>
                <div class="custom-control custom-checkbox ml-3 col-5">
                  <input
                    type="checkbox"
                    v-model="modoFeria"
                    class="custom-control-input"
                    id="checkBoxFeria"
                  />
                  <label class="custom-control-label" for="checkBoxFeria"
                    >Modo Feria</label
                  >
                </div>
                <div class="custom-control custom-checkbox col-5">
                  <input
                    type="checkbox"
                    v-model="modoMerma"
                    class="custom-control-input"
                    id="checkBoxMerma"
                  />
                  <label class="custom-control-label" for="checkBoxMerma"
                    >Merma</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!productoNoEncontrado"
            :title="producto.descripcion"
            class="col-sm-12 col-md d-flex justify-content-center"
          >
            <div class="">
              {{ producto.nombre }}
            </div>
          </div>
          <button
            v-if="productoNoEncontrado"
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#modalAgregarProducto"
          >
            Agregar Producto
          </button>
          <div class="form-group row col-sm-12 d-flex justify-content-center">
            <div class="input-group-prepend col-4">
              <span :class="producto.id && producto.stock<=3?'input-group-text text-danger':'input-group-text'" id="basic-addon1">Cantidad: {{producto.stock?("MAX: "+producto.stock):''}}</span>
            </div>
            <div class="col-8">
              <input
                v-model="productoCompra.cantidad"
                class="form-control"
                type="number"
                id="input-cantidad"
                :disabled="producto.id === null ? true : false"
              />
            </div>
          </div>
          <div
            class="form-group row d-flex col-sm-12 justify-content-center ml-3"
          >
            <div class="">
              <span class="input-group-text w-100 row" id="basic-addon1">
                <input
                  class="col-10"
                  @blur="
                    productoCompra.precio = aplicarDescuento(
                      producto.precio,
                      producto.descuento
                    )
                  "
                  type="number"
                  v-model="producto.descuento"
                  :disabled="producto.id === null ? true : false"
                />
                <p class="col-2">%Dscto</p>
              </span>
            </div>
          </div>
          <div class="form-group row d-flex col-sm-6 justify-content-center">
            <div class="">
              <input
                v-model="productoCompra.precio"
                class="form-control"
                type="number"
                id="example-number-input"
                :disabled="producto.id === null ? true : false"
              />
            </div>
          </div>
          <div class="form-group col-sm-12 d-flex justify-content-center mx-1">
            <button
              @click="agregarProducto()"
              class="btn btn-success btn-block"
              :disabled="producto.id === null ? true : false"
            >
              <i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- TABLA CON PRODUCTOS COMPRADOS -->
      <table v-if="venta.productos.length > 0" class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Producto</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Precio Unitario</th>
            <th scope="col">Subtotal</th>
            <th scope="col">
              <button
                title="Eliminar Todos"
                @click="limpiarProductos()"
                class="btn btn-danger"
              >
                <i class="fa fa-trash"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(producto, index) in venta.productos"
            :key="producto.productoId"
          >
            <td>{{ index + 1 }}</td>
            <th scope="row">{{ producto.producto }}</th>
            <td>{{ producto.cantidad }}</td>
            <td>{{ producto.precio }}</td>
            <td>{{ producto.precio * producto.cantidad }}</td>
            <td>
              <button
                @click="eliminarProducto(index)"
                :title="'Eliminar ' + producto.producto"
                class="btn btn-danger"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="4">Total de Bultos: {{ obtenerCantidadBultos() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="d-flex flex-row-reverse pb-5 mt-2">
            <button class="btn btn-success mx-1" @click="guardar()" :disabled="venta.productos.length>0 ? false:true" >Guardar ({{totalPedido()}})</button>
            <button class="btn btn-danger mx-1" @click="limpiar()">Limpiar</button>
            <button class="btn btn-info mx-1" @click="copiarDetalle()" :disabled="venta.productos.length>0 ? false:true">Copiar</button>
        </div> -->
    <div class="row pb-5 mt-2 row">
      <div class="custom-control custom-checkbox col-6">
        <input
          type="checkbox"
          v-model="transbank"
          class="custom-control-input"
          id="checkBoxTransbank"
        />
        <label class="custom-control-label" for="checkBoxTransbank"
          >Paga con Transbank</label
        >
      </div>
      <button
        class="btn btn-success col-6"
        @click="guardar()"
        :disabled="
          venta.productos.length > 0 && cargando === false ? false : true
        "
      >
        {{ this.venta.id === null ? "Guardar" : "Editar" }} ({{
          totalPedido()
        }})
      </button>
      <button class="btn btn-danger col-6" @click="limpiar()">Limpiar</button>
      <button
        class="btn btn-info col-6"
        @click="copiarDetalle()"
        :disabled="venta.productos.length > 0 ? false : true"
      >
        Copiar
      </button>
    </div>

    <!-- <div id="modalAgregarProducto" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <MantenedorProducto></MantenedorProducto>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success btn-block" data-dismiss="modal">Listo!</button>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- MODAL BUSCAR PRODUCTO -->
    <!-- <div id="modalDetalle" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <Buscador :busqueda="this.busqueda" @seleccionado="encontrado($event)"></Buscador>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
                
            </div>
        </div> -->
  </div>
</template>

<script>
// import Buscador from '@/components/BuscarProducto.vue'
const axios = require("@/assets/functions/axiosClient.js");
const moment = require("moment");
const _ = require("lodash");
window.$ = window.jQuery = require("jquery");

export default {
  components: {
    // Buscador
  },
  watch: {
    ventaId: function (newValue) {
      this.venta = this.getVentaById(newValue);
    },
    busqueda: async function (newVar) {
      if (newVar.toString().includes("\n")) {
        await this.buscarCodigo();
      }
    },
    modoFeria: function (newValue) {
      this.deducirEstado();
      this.deducirCliente();
      if (newValue == true) {
        this.venta.sucursalId = 2;
      } else {
        this.venta.sucursalId = 1;
      }
      document.getElementById("buscador").focus();
    },
    modoMerma: function () {
      this.deducirEstado();
      this.deducirCliente();
      document.getElementById("buscador").focus();
    },
    transbank: function (newValue) {
      if (newValue == true) {
        this.venta.medioPagoId = 3;
      } else {
        this.venta.medioPagoId = 1;
      }
    },
  },
  metaInfo() {
    return {
      title: "POS de Ventas - Sexduceme",
    };
  },
  created() {
    this.obtenerEstados();
  },
  methods: {
    hideCampos(campos) {
      campos.forEach((campo) => {
        document.getElementById(campo).style.display = "none";
      });
    },
    showCampos(campos) {
      campos.forEach((campo) => {
        document.getElementById(campo).style.display = "block";
      });
    },
    setNow() {
      this.venta.fechaCompra = moment(new Date()).format("yyyy-MM-DDTHH:mm");
    },
    deducirEstado() {
      this.venta.estadoId = this.modoMerma || this.modoFeria ? 4 : 2;
    },
    deducirCliente() {
      if (this.modoFeria) {
        this.venta.cliente = "FERIA";
      } else if (this.modoMerma) {
        this.venta.cliente = "MERMA";
      } else {
        this.venta.cliente = "";
      }
    },
    async procesarMasivo() {
      let lineas = this.inputMasivo.split("\n");
      let productosComprar = [];
      lineas.forEach((linea) => {
        if (linea.includes("(cod: ")) {
          let palabras = linea.split(" ");
          productosComprar.push({
            cantidad: palabras[0],
            codigo: linea
              .replaceAll("*", "")
              .split("(cod: ")[1]
              .replace(")", "\n"),
          });
        } else if (linea.includes("Medio de entrega: ")) {
          this.metodoEntrega = linea.split("Medio de entrega: ")[1];
          console.log(this.metodoEntrega);
        }
      });

      console.log(productosComprar);

      this.modoFeria = true;
      for (const producto of productosComprar) {
        for (let i = 0; i < producto.cantidad; i++) {
          this.productoCompra.cantidad = producto.cantidad;
          await new Promise((r) => setTimeout(r, 1000));

          this.busqueda = producto.codigo;
        }
      }
    },
    copiarDetalle() {
      console.log("A crear Detalle");
      let aux = document.createElement("textarea");
      aux.innerHTML = this.obtenerDetalle();
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
    },
    obtenerDetalle() {
      console.log("A obtener Detalle");
      let total = 0;
      let detalle = "Detalle de su pedido:\n\n";

      this.venta.productos.forEach((producto) => {
        detalle +=
          producto.cantidad +
          " " +
          producto.producto +
          ": " +
          this.formatPrice(producto.precio * producto.cantidad) +
          "\n";
        total += producto.cantidad * producto.precio;
      });

      detalle += "\n\nSubtotal: " + this.formatPrice(total);

      detalle +=
        "\n\nEntrega (" +
        this.metodoEntrega +
        "): " +
        (this.metodoEntrega === "Retiro" ? this.formatPrice(0) : "?") +
        "\n\nTotal a Pagar: " +
        (this.metodoEntrega === "Retiro" ? this.formatPrice(total) : "?");
      return detalle;
    },
    formatPrice(price) {
      let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
      return "$ " + priceString.substring(0, priceString.length - 2);
    },
    obtenerEstados() {
      axios
        .get("/estados-ventas")
        .then((response) => {
          if (response.status === 200) {
            this.estados = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    obtenerCantidadBultos() {
      let bultos = 0;
      this.venta.productos.forEach((producto) => {
        bultos += Number(producto.cantidad);
      });
      return bultos;
    },
    totalPedido() {
      let total = 0;
      this.venta.productos.forEach((producto) => {
        total += producto.cantidad * producto.precio;
      });
      return total;
    },
    aplicarDescuento(precio, descuento) {
      let precioDescuento = precio - precio * (descuento / 100);
      return precioDescuento - (precioDescuento % 10);
    },
    limpiar() {
      this.venta = {
        cliente: "",
        direccion: "",
        estadoId: 2,
        medioPagoId: 1,
        sucursalId: this.modoFeria == true ? 2 : 1,
        fechaCompra: moment(new Date()).format("yyyy-MM-DDTHH:mm"),
        id: null,
        telefono: 56,
        productos: [],
      };
      this.limpiarProducto();
      this.transbank = false;
    },
    guardar() {
      if (this.validar()) {
        this.cargando = true;
        axios
          .post("/inventarios/ventas", this.venta)
          .then((response) => {
            if (response.status === 201) {
              this.limpiar();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.cargando = false;
          });
      } else {
        alert("Campos Faltantes");
      }
    },
    validar() {
      if (this.venta.telefono.length != 11 && this.venta.telefono > 0) {
        this.venta.telefono = null;
      }
      return true;
    },
    agregarProducto() {
      this.productoCompra.produtoId = this.producto.id;
      this.productoCompra.codigo = this.producto.codigo;
      this.productoCompra.producto = this.producto.nombre;
      if (this.venta.id === null) {
        this.agregarProductoLista(this.productoCompra);
      } else {
        this.agregarProductoVenta(this.venta.id, this.productoCompra);
      }
      this.limpiarProducto();
      this.setNow();
      document.getElementById("buscador").focus();
    },
    agregarProductoLista(productoCompra) {
      let aux = {};
      Object.assign(aux, productoCompra);
      let search = _.find(this.venta.productos, {
        codigo: productoCompra.codigo,
      });
      if (search) {
        search.cantidad += Number(productoCompra.cantidad);
      } else {
        this.venta.productos.push(aux);
      }
    },
    agregarProductoVenta(ventaId, productoCompra) {
      this.cargando = true;
      axios
        .post("/inventarios/ventas/" + ventaId + "/productos", {
          produtoId: productoCompra.produtoId,
          cantidad: productoCompra.cantidad,
          precio: productoCompra.precio,
        })
        .then((response) => {
          if (response.status === 201) {
            console.log("Guardado" + response);
            this.agregarProductoLista(productoCompra);
          } else {
            console.table(response);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("No se pudo Guardar: " + error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    limpiarProducto() {
      this.busqueda = "";
      this.producto = {
        id: null,
        codigo: null,
        nombre: null,
        descripcion: null,
        detalles: null,
        instagram: null,
        precio: null,
        descuento: null,
        stock: null,
      };
      this.productoCompra = {
        pedidoId: null,
        produtoId: null,
        producto: null,
        cantidad: 0,
        precio: 0,
        codigo: null,
        subtotal: null,
      };
    },
    eliminarProducto(index) {
      console.log("Eliminar Producto");
      console.log(this.venta.productos[index]);
      this.venta.id === null
        ? this.venta.productos.splice(index, 1)
        : this.eliminarVentaProducto(
            this.venta.id,
            this.venta.productos[index].produtoId,
            index
          );
    },
    eliminarVentaProducto(ventaId, productoId, index) {
      console.log(ventaId, productoId, index);
      this.cargando = true;
      axios
        .delete("/inventarios/ventas/" + ventaId + "/productos", {
          data: { produtoId: productoId },
        })
        .then((response) => {
          if (response.status === 200) {
            this.venta.productos.splice(index, 1);
          } else {
            console.table(response);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("No se Pudo eliminar: " + error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    limpiarProductos() {
      this.venta.productos = [];
    },
    encontrado(codigo) {
      this.buscarCodigo(codigo);
      window.jQuery.noConflict();
      window.$("#modalDetalle").modal("hide");
    },
    buscarProducto() {
      // if(this.productoNoEncontrado==null | this.productoNoEncontrado==true){
      window.jQuery.noConflict();
      window.$("#modalDetalle").modal();
      // }
    },
    getVentaById(ventaId) {
      axios
        .get("/inventarios/ventas/" + ventaId)
        .then((response) => {
          if (response.status === 200) {
            this.venta = response.data.data;
            if (!this.venta.telefono) this.venta.telefono = "56";
            this.venta.fechaCompra = moment(this.venta.fechaCompra).format(
              "yyyy-MM-DDTHH:mm"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    async buscarCodigo() {
      if (this.busqueda.length > 0) {
        this.cargando = true;
        await axios
          .get("/inventarios/productos/codigo/" + this.busqueda)
          .then((response) => {
            if (response.status === 200) {
              this.producto = response.data.data;
              this.productoNoEncontrado = false;
              this.productoCompra.precio =
                this.modoMerma === true
                  ? 0
                  : this.aplicarDescuento(
                      this.producto.precio,
                      this.producto.descuento
                    );
              this.productoCompra.cantidad = 1;
              if (this.modoFeria === true) {
                this.agregarProducto();
              }
            } else if (response.status === 204) {
              this.productoNoEncontrado = true;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.cargando = false;
          });
      }
    },
  },
  props: ["ventaId"],
  data() {
    return {
      inputMasivo: "",
      metodoEntrega: null,
      cargando: false,
      modoFeria: false,
      modoMerma: false,
      transbank: false,
      estados: [],
      productoNoEncontrado: null,
      busqueda: "",
      productoCompra: {
        cantidad: 0,
        codigo: null,
        precio: null,
        produtoId: null,
      },
      producto: {
        id: null,
        codigo: null,
        nombre: null,
        descripcion: null,
        detalles: null,
        instagram: null,
        precio: null,
        descuento: null,
        stock: null,
      },
      productos: [],
      venta: {
        cliente: "",
        direccion: "",
        estadoId: 2,
        medioPagoId: 1,
        sucursalId: this.modoFeria == true ? 2 : 1,
        fechaCompra: moment(new Date()).format("yyyy-MM-DDTHH:mm"),
        id: null,
        telefono: 56,
        productos: [],
      },
    };
  },
};
</script>

<style>
.boxsizingBorder {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>