<template>
    <div class="container">
        <div id="botoneraGaleria" class="row row-cols-1 row-cols-md-3 mb-2 bg-rosa">
            <!-- ORDENAR PRODUCTOS -->
            <div class="row">
                <div class="dropdown mr-1 col-8 col-md">
                    <button type="button" class="btn btn-block btn-secondary dropdown-toggle bg-rosa" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,20">
                    {{orderText}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                        <a class="dropdown-item seleccionable" @click="this.campoOrdenar(this.O_precio,'Precio',this)">Precio</a>
                        <a class="dropdown-item seleccionable" @click="this.campoOrdenar(this.O_descuento,'Descuento',this)">Descuento</a>
                        <a class="dropdown-item seleccionable" @click="this.campoOrdenar(this.O_nombre,'Nombre',this)">Nombre</a>
                        <!-- <a class="dropdown-item seleccionable" @click="this.campoOrdenar(this.O_fecha,'Fecha Actualización',this)">Fecha Actualización</a> -->
                    </div>
                </div>
                <div class="col col-md">
                    <i @click="subirBajar()" class="fa seleccionable font-large" :class="ascendente ? 'fa-arrow-down':'fa-arrow-up'"></i>
                </div>
            </div>

            <!-- FILTRAR CATEGORÍAS -->
            <div class="row">
                <div class="dropdown mr-1 col-8 col-md">
                    <button type="button" class="btn btn-block btn-secondary dropdown-toggle bg-rosa" id="dropdownfilterCategoria" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,20" :disabled="categorias.length>0?false:true">
                    Filtrar
                    </button>
                    <div class="dropdown-menu form-check" id="dropdownfilterCategoria">
                        <div v-for="categoria in categorias" :key="categoria.id">
                            <div class="row bg-rosa enmarcar d-flex justify-content-between align-items-center">
                                <input @click="categoriaFiltrada(categoria.id)" class="col-2" type="checkbox" :id="categoria.id" :checked="categoria.checked">
                                <label class="col-8">
                                        {{categoria.nombre}}
                                </label>
                                <i @click="seleccionarUno(categoria.id)" class="col-2 fa fa-chevron-circle-left seleccionable"></i>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- FILTRAR AGOTADOS -->
            <!-- <div class="custom-control custom-switch">
                <input type="checkbox" v-model="showAgotados"  class="custom-control-input" id="customSwitch1">
                <label class="custom-control-label" for="customSwitch1">Mostrar Agotados</label>
            </div> -->
        </div>
        
        <!-- LISTAR PRODUCTOS -->
        <div class="card-deck row row-cols-1 row-cols-md-3" id="galery">
            <div class="col mb-4 align-items-stretch" :class="((producto.stock<1 && !showAgotados) || !filtrarCategorias(producto)) ? 'desaparecer':''" v-for="(producto) in productos" :key="producto.id">
                <ProductoVertical :producto="producto" @seleccionado="mostrarModal($event)"></ProductoVertical>
                <button @click="editar=producto.codigo" type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#modalAgregarProducto">
                    <i class="fa fa-pencil"></i> Editar <br> {{producto.nombre}}
                </button>
            </div>
        </div>
        
        <div id="modalDetalle" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <ProductoDetalle :id="seleccionado" @top="scrollModalTop()" ></ProductoDetalle>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
                
            </div>
        </div>


        <!-- Mantenedor de Productos -->

        <div id="modalAgregarProducto" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <MantenedorProducto :productoCode='this.editar'></MantenedorProducto>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success btn-block" data-dismiss="modal">Listo!</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import ProductoVertical from '@/components/ProductoVertical.vue';
import ProductoDetalle from '@/components/ProductoDetalle.vue';
import MantenedorProducto from '@/views/MantenedorProducto.vue';
window.$ = window.jQuery = require('jquery');
const _ = require('lodash');

export default {
    name: 'Galeria',
    props: ['productos'],
    components:{
        ProductoVertical,
        ProductoDetalle,
        MantenedorProducto
    },
    watch:{
        productos: function(){
            
        }
    },
    data(){
        return{
            editar:null,
            seleccionado:null,
            orderText: "Ordenar por...",
            O_precio: 1,
            O_descuento: 2,
            O_nombre: 3,
            O_stock: 4,
            O_fecha: 5,
            showAgotados:false,
            ascendente: true,
            ordenarPor: 3,
            categorias: []
        }
    },
    beforeUpdate(){
        this.ordenar(this.productos);
        this.filtrar
        this.categorias = this.extraerCategorias(this.productos);
    },
    methods:{
        scrollModalTop(){
            window.$('#modalDetalle').on('shown.bs.modal', function () {
            window.$('#modalDetalle').animate({ scrollTop: 0 }, 'slow');
            })
        },
        formatPrice(price){
            let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
            return '$ ' + priceString.substring(0,priceString.length-2);
        },
        mostrarModal(productoId){
            this.seleccionado=productoId;
            window.jQuery.noConflict();
            window.$("#modalDetalle").modal();
        },
        aplicarDescuento(precio,descuento){
            return precio-(precio*(descuento/100));
        },
        ordenar(array){


            if(this.ascendente){
                if(this.ordenarPor===this.O_precio)
                    array.sort(((a,b)=>b.precio-a.precio));
                else if(this.ordenarPor===this.O_descuento)
                    array.sort(((a,b)=>b.descuento-a.descuento));
                else if(this.ordenarPor===this.O_fecha)
                    array.sort(((a,b)=>b.fechaActualizacion-a.fechaActualizacion));
                else if(this.ordenarPor===this.O_nombre)
                    array.sort(((a,b)=>{
                        if(b.nombre<a.nombre) return -1;
                        else if(b.nombre>a.nombre) return 1;
                        else return 0;
                        }
                    ));
            }
            else{
                if(this.ordenarPor===this.O_precio)
                    array.sort(((a,b)=>a.precio-b.precio));
                else if(this.ordenarPor===this.O_descuento)
                    array.sort(((a,b)=>a.descuento-b.descuento));
                else if(this.ordenarPor===this.O_nombre)
                    array.sort(((a,b)=>{
                        if(a.nombre<b.nombre) return -1;
                        else if(a.nombre>b.nombre) return 1;
                        else return 0;
                        }
                    ));
            }
            return array;
        },
        mostrarAgotados(){
            this.showAgotados = !this.showAgotados;
        },
        subirBajar(){
            this.ascendente = !this.ascendente;
            this.ordenar(this.productos);
        },
        campoOrdenar(seleccion,text){
            if(text.length>0)
                this.orderText = text;
            this.ordenarPor = seleccion;
            this.ordenar(this.productos);
        },
        extraerCategorias(productos){
            let categorias=[];
            productos.forEach(producto => {
                categorias = categorias.concat(producto.categorias);
            });
            var hash = {};
            categorias = categorias.filter(current => {
                var exists = !hash[current.id];
                if(current.checked===undefined)
                    current.checked=true;
                hash[current.id] = true;
                return exists;
                });
            return _.sortBy(categorias,['nombre']);
        },
        filtrarCategorias(producto){
            for (let i = 0; i < this.categorias.length; i++) {
                for (let j = 0; j < producto.categorias.length; j++) {
                    if((this.categorias[i].id === producto.categorias[j].id) && this.categorias[i].checked)
                        return true
                }
                
            }

            return false;
        },
        categoriaFiltrada(id){
            let categoria = this.categorias.find( categoria => {
                    return categoria.id === id
                });
                categoria.checked = !categoria.checked;
        },
        seleccionarUno(id){
            this.categorias.forEach(categoria => {
                if(categoria.id===id)
                    categoria.checked=true;
                else
                    categoria.checked=false;
            });
        }
        
    }
}
</script>

<style>

.precio{
    color: black;
    font-size: large;
}

/* .precio:hover{
    color: purple;
} */

.card img{
    padding: 0%;
    border-radius: 0%;
}

.fondo-burdeo{
  background-color: #740B33;
  color: #F8AFCC;   
}

.titulo{
    font-size: large;
    height: 5%;
    background-color: #F8AFCC;
    color: #740B33;
    border-style: solid;
    border-color:#740B33;
}

.redondear{
    border-radius: 0%;
}

.bg-rosa{
    background-color: #F8AFCC;
    color: #740B33;
}

.btn.bg-rosa:hover{
    background-color: #BB1253 ;
    color: #F8AFCC;
}

a:hover{
    text-decoration: none;
}

.tachado{
    text-decoration: line-through
}

.desaparecer{
    display: none;
}

.gris{
    /* grayscale */
    -webkit-filter: grayscale(1); 
    filter: gray; 
    filter: grayscale(1);

    /* multiply */
    background-color: rgba(190, 50, 50, 0.65);
    background-blend-mode: multiply;
}

.seleccionable:hover{
    background-color: #F8AFCC;
    color: #740B33;
    cursor: pointer;
}

.central-vertical{
    align-items: center;
    vertical-align: middle;
}
.rosa-trans{
    /* grayscale */
    -webkit-filter: grayscale(1); 
    filter: #F8AFCC; 

    /* multiply */
    background-color: rgba(253, 225 , 234   , 0.2);
    background-blend-mode: multiply;
    color: #740B33!important;
}

.rosa-trans > .card-body > .precio{
    color: #740B33;
}

.font-large{
    font-size: xx-large;
}

.enmarcar{
        border: solid;
        border-width: 1px;
        padding: 1%;
    }
</style>