<template>
    <div>
        <div class="alert alert-warning">
            Valor total del inventario: {{totalInventario()}}
        </div>

        <!-- <div v-if="!this.cargando">
            <img src="https://media.tenor.com/images/49552aa72f8a494e7c4fdf3c5f69f3e4/tenor.gif" alt="cargando">
        </div>
        <div class="alert alert-warning" v-else-if="!this.productos.length>0">
            {{mensaje}}
        </div> -->
        
        <table v-if="productos.length>0" class="table">
            <!-- <thead class="thead-dark">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Código</th>
                <th scope="col">Producto</th>
                <th scope="col">Stock</th>
                <th scope="col">Confirmar</th>
                </tr>
            </thead> -->
            <tbody>
                <tr v-for="(producto) in this.productos" :key="producto.productoId">
                    <!-- <td >{{index+1}}</td> -->
                    <td><input type="checkbox"> </td>
                    <td>{{producto.codigo}}</td>
                    <th>{{producto.nombre}}</th>
                    
                    <td>
                        <div class="row">
                            <td class="col-xl-4 col-md-12">{{producto.stock}}</td>
                            <input class="col-xl-4 col-md-12" type="number" v-model="producto.input" min="0" step="1"/>
                            <p class="col-xl-4 col-md-12 bg-success">{{producto.stock-producto.input}}</p>
                        </div>
                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Inventario",
    components:[
    ],
    metaInfo(){
            return{
                title: "Inventario - Sexdúceme"
            }
        },
    data(){
        return{
            productos:[]
        }
    },
    methods:{
        totalInventario(){
            let total = 0;
            this.productos.forEach(producto => {
                total += producto.precio * producto.stock
            });
            return total;
        }
    },
    created () {
    const axios = require('@/assets/functions/axiosClient.js');
    this.cargando = true;
    axios
      .get('/inventarios/productos')
      // .get('/inventarios/productos')
      .then(response => {
        this.productos = response.data.data;
        this.productos.forEach(producto=>{
              producto.input = producto.stock
          })
        })
        .catch(error=>{
          console.log(error);
        })
      .finally(()=>{
        this.cargando = false;
      }
      )
  }
}
</script>