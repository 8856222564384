<template>
    <div >
        <MantenedorVentas></MantenedorVentas>
    </div> 
</template>

<script>

import MantenedorVentas from '@/components/MantenedorVentas.vue';

export default {
    components:{
        MantenedorVentas
    },
     metaInfo(){
            return{
                title: "Lista de Ventas"
            }
        },
    data(){
        return{
        }
    }
}
</script>