const { aplicarDescuento } = require('@/assets/functions/calculosFinancieros.js')

module.exports = class ProductoAgregar {
    constructor(producto) {
        if (producto) {
            this.produtoId = producto.id;
            this.codigo = producto.codigo;
            this.producto = producto.nombre;
            this.cantidad = 1;
            this.descuento = producto.descuento;
            this.precio = aplicarDescuento(producto.precio, this.descuento);
        } else {
            this.produtoId = null;
            this.codigo = null;
            this.producto = '';
            this.cantidad = 0;
            this.descuento = 0;
            this.precio = 0;
        }
    }

    actualizar() {
        this.precioFinal = aplicarDescuento(this.precio, this.descuento)
    }
}