<template>
    <div>
        <div :id="'carousel'+origen+id" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li v-for="(imagen,index) in imagenes" :key="imagen.url" :data-target="'#carousel'+origen+id" :data-slide-to="index" :class="index===0 ? 'active':''"></li>
            </ol>
            <div class="carousel-inner">
                <div v-for="(imagen,index) in imagenes" :key="imagen.url" class="carousel-item" :class="index===0 ? 'active':''">
                    <img :src="imagen.url" class="d-block w-100" alt="...">
                </div>
            </div>
            <a class="carousel-control-prev" :href="'#carousel'+origen+id" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" :href="'#carousel'+origen+id" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Carousel',
    props:['imagenes','id','origen'],
    data(){
        return{

        }
    },
}
</script>

<style>

</style>