<template>
    <div class="container">
        <h1 class="col-12 my-3">Mantenedor de Compras:</h1>
        <!-- DATOS DEL PRODUCTO -->
        <div class="row">
            <h2 class="col-12  text-left">Datos de la Compra:</h2>
            <div class="row row-cols-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Código:</span>
                    </div>
                    <input v-model="compra.codigo" type="text" class="form-control">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Orden de transporte:</span>
                    </div>
                    <input v-model="compra.ot" type="text" class="form-control">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Costo de transporte:</span>
                    </div>
                    <input v-model="compra.costoOt" type="text" class="form-control">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Nombre:</span>
                    </div>
                    <input v-model="compra.nombre" type="text" class="form-control">
                </div>
                <!-- COMBOS SELECTORES -->
                <div class="form-group d-flex justify-content-center px-0 my-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Proveedor:</span>
                    </div>
                    <select v-model="compra.proveedorId" class="form-control">
                        <option v-for="proveedor in proveedores" :key="proveedor.id" :value="proveedor.id">{{proveedor.nombre}}</option>
                    </select>
                </div>
                <div class="form-group d-flex justify-content-center px-0 my-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Estado:</span>
                    </div>
                    <select v-model="compra.estadoId" class="form-control">
                        <option v-for="estado in estados" :key="estado.id" :value="estado.id">{{estado.nombre}}</option>
                    </select>
                </div>
                <div class="form-group d-flex justify-content-center px-0 my-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Medio de Entrega:</span>
                    </div>
                    <select v-model="compra.medioId" class="form-control">
                        <option v-for="medio in medios" :key="medio.id" :value="medio.id">{{medio.nombre}}</option>
                    </select>
                </div>
                <!-- FECHAS -->
                <div class="form-group d-flex justify-content-center col-6">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Fecha de Compra:</span>
                    </div>
                    <div>
                        <input v-model="compra.fechaPedido" class="form-control" type="date" id="example-date-input">
                    </div>
                </div>
                <div class="form-group d-flex justify-content-center col-6">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Fecha de Recepción:</span>
                    </div>
                    <div>
                        <input v-model="compra.fechaRecepcion" class="form-control" type="date" id="example-date-input">
                    </div>
                </div>
            </div>
            <!-- PRODUCTOS COMPRADOS -->
            <h2 class="col-12  text-left">Productos de la Compra:</h2>
            <div class="row col">
                <div class="row col">
                    <div class="form-group row col d-flex justify-content-center">
                        <div class="input-group-prepend col-4">
                            <span class="input-group-text" id="basic-addon1">Buscar:</span>
                        </div>
                        <div class="col-8 px-0">
                            <input id="buscador" @blur="buscarCodigo(this.busqueda)" v-model="busqueda" type="text" class="form-control">
                        </div>
                    </div>
                    <div v-if="!productoNoEncontrado" :title="producto.descripcion" class="col d-flex justify-content-center">
                        {{producto.nombre}}
                    </div>
                    <button v-if="productoNoEncontrado" type="button" class="btn btn-primary " data-toggle="modal" data-target="#modalAgregarProducto">
                        Agregar Producto
                    </button>
                    <div class="form-group row col d-flex justify-content-center">
                        <div class="input-group-prepend col-4">
                            <span class="input-group-text" id="basic-addon1">Cantidad:</span>
                        </div>
                        <div class="col-8">
                            <input v-model="productoCompra.cantidad" class="form-control" type="number" id="example-number-input" :disabled="producto.id===null ? true:false">
                        </div>
                    </div>
                    <div class="form-group row col d-flex justify-content-center">
                        <div class="input-group-prepend col px-0">
                            <span class="input-group-text w-100" id="basic-addon1">$</span>
                        </div>
                        <div class="col px-0">
                            <input v-model="productoCompra.precio" class="form-control" type="number" id="example-number-input" :disabled="producto.id===null ? true:false">
                        </div>
                    </div>
                    <div class="form-group d-flex justify-content-center mx-1">
                        <button @click="agregarProducto()" class="btn btn-success" :disabled="producto.id===null ? true:false">
                            <i class="fa fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- TABLA CON PRODUCTOS COMPRADOS -->
            <table v-if="compra.productos.length>0" class="table">
                <thead class="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Precio Unitario</th>
                    <th scope="col">Subtotal</th>
                    <th scope="col"><button title="Eliminar Todos" @click="limpiarProductos()" class="btn btn-danger"><i class="fa fa-trash"></i></button></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(producto,index) in compra.productos" :key="producto.productoId">
                        <td>{{index+1}}</td>
                        <th scope="row">{{producto.producto}}</th>
                        <td>{{producto.cantidad}}</td>
                        <td>{{producto.precio}}</td>
                        <td>{{producto.precio*producto.cantidad}}</td>
                        <td><button @click="eliminarProducto(index)" :title="'Eliminar '+producto.producto" class="btn btn-danger"><i class="fa fa-trash"></i></button></td>
                    </tr>
                </tbody>
            </table>
            <p>Total de Bultos: {{obtenerCantidadBultos()}}</p>
        </div>

        <!-- BOTONERA -->

        <div class="d-flex flex-row-reverse pb-5 mt-2">
            <button class="btn btn-success mx-1" @click="guardar()">Guardar ({{totalPedido()}})</button>
            <button class="btn btn-danger mx-1" @click="limpiar()">Limpiar</button>
        </div>

        <div id="modalAgregarProducto" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <MantenedorProducto :productoCode='this.busqueda'></MantenedorProducto>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success btn-block" data-dismiss="modal">Listo!</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL BUSCAR PRODUCTO -->
        <!-- <div id="modalDetalle" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <Buscador :busqueda="this.busqueda" @seleccionado="encontrado($event)"></Buscador>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-block" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
                
            </div>
        </div> -->

    </div>
</template>

<script>

import MantenedorProducto from '@/views/MantenedorProducto.vue';
// import Buscador from '@/components/BuscarProducto.vue'
window.$ = window.jQuery = require('jquery');
export default {
    name: 'MantenedorCompras',
     metaInfo(){
            return{
                title: "Mantenedor de Productos"
            }
        },
    components:{
        MantenedorProducto,
        // Buscador
        },
    watch:{
        busqueda: function(oldValue,newValue){
            console.log(oldValue+"->"+newValue);
        }
    },
    methods:{
        limpiar(){
            this.compra = {
                id: null,
                nombre: null,
                codigo: null,
                ot: null,
                montoTotal: null,
                proveedorId: null,
                proveedor: null,
                estadoId: null,
                estado: null,
                medioId: null,
                medio: null,
                fechaPedido: null,
                fechaRecepcion: null,
                productos: [
                ]
            }
            this.limpiarProducto();
        },
        limpiarProducto(){
            this.busqueda = "";
            this.producto = {
                id: null,
                codigo: null,
                nombre: null,
                descripcion: null,
                detalles: null,
                instagram: null,
                precio: null,
                descuento: null,
                stock: null
            };
            this.productoCompra = {
                pedidoId: null,
                produtoId: null,
                producto: null,
                cantidad: 0,
                precio: 0,
                codigo: null,
                subtotal: null
            }
        },
        guardar(){
            const axios = require('@/assets/functions/axiosClient.js');
            axios.post('/inventarios/pedidos',this.compra)
                .then(response => {
                    console.log('Guardado'+response);
                    this.limpiar();
                })
                .catch(error=>{
                    console.log(error);
                })
                .finally(()=>{
                this.cargando = false;
                }
                )
        },
        agregarProducto(){
            let aux = {};
            this.productoCompra.produtoId = this.producto.id;
            this.productoCompra.codigo = this.producto.codigo;
            this.productoCompra.producto = this.producto.nombre;
            Object.assign(aux, this.productoCompra);
            this.compra.productos.push(aux);
            this.limpiarProducto();
            document.getElementById("buscador").focus();
        },
        encontrado(codigo){
            this.buscarCodigo(codigo);
            window.jQuery.noConflict();
            window.$("#modalDetalle").modal('hide');
        },
        buscarProducto(){
            // if(this.productoNoEncontrado==null | this.productoNoEncontrado==true){
                window.jQuery.noConflict();
                window.$("#modalDetalle").modal();
            // }
        },
        buscarCodigo(codigo){
            if(this.busqueda.length>0 && this.encontrado!=null){
                const axios = require('@/assets/functions/axiosClient.js');
                    axios.get('/inventarios/productos/codigo/'+codigo)
                        .then(response => {
                            if(response.status===200){
                                console.table(response)
                                this.producto=response.data.data
                                this.productoNoEncontrado=false;
                            }
                            else if(response.status===204){
                                this.productoNoEncontrado=true;
                            }
                        })
                        .catch(error=>{
                            console.log(error);
                        })
                        .finally(()=>{
                        this.cargando = false;
                        }
                        )
            }
        },
        eliminarProducto(index){
            this.compra.productos.splice(index,1);
        },
        totalPedido(){
            let total = 0;
            this.compra.productos.forEach(producto => {
                total += producto.cantidad*producto.precio;
            });
            return total;
        },
        obtenerCantidadBultos(){
            let bultos = 0;
            this.compra.productos.forEach(producto => {
                bultos += Number(producto.cantidad);
            });
            return bultos;
        },
        limpiarProductos(){
            this.compra.productos = [];
        }
    },
    created(){
    },
    data(){
        return{
            productoNoEncontrado:null,
            busqueda: "",
            producto:{
                id: null,
                codigo: null,
                nombre: null,
                descripcion: null,
                detalles: null,
                instagram: null,
                precio: null,
                descuento: null,
                stock: null
            },
            productoCompra: {
                pedidoId: null,
                produtoId: null,
                producto: null,
                cantidad: 0,
                precio: 0,
                codigo: null,
                subtotal: null
            },
            proveedores:[
                {
                    id: 1,
                    nombre: "SexShop Mayorista"
                },
                {
                    id: 2,
                    nombre: "Duende Mágico"
                },
                {
                    id: 3,
                    nombre: "Shenzhen AISIAO Trade"
                },
                {
                    id: 4,
                    nombre: "Miel Para Dormir"
                }
            ],
            estados:[
                {
                    id:1,
                    nombre:"Realizado"
                },
                {
                    id:2,
                    nombre:"Confirmado"
                },
                {
                    id:3,
                    nombre:"Pagado"
                },
                {
                    id:4,
                    nombre:"Despachado"
                },
                {
                    id:5,
                    nombre:"Recibido"
                }
            ],
            medios:[
                {
                    id:1,
                    nombre:"Retiro en Tienda"
                },
                {
                    id:2,
                    nombre:"Entrega a Coordinar"
                },
                {
                    id:3,
                    nombre:"Envío a Domicilio"
                },
                {
                    id:4,
                    nombre:"Envío a Sucursal"
                }
            ],
            compra:{
                id: null,
                nombre: null,
                codigo: null,
                ot: null,
                compraOt: null,
                montoTotal: null,
                proveedorId: null,
                proveedor: null,
                estadoId: null,
                estado: null,
                medioId: null,
                medio: null,
                fechaPedido: null,
                fechaRecepcion: null,
                productos: [
                ]
            }
        }
    }
}
</script>