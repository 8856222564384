<template>
  <b-container>
    <b-row>
      <b-col> {{ resumen.bultos }} Bultos </b-col>
      <b-col> Total de la Compra: {{ formatPrice(resumen.total) }} </b-col>
    </b-row>
    <b-row
      v-for="(producto, index) in productos"
      :key="index"
      align-h="start"
      align-v="center"
      :class="
        'text-left border border-secondary ' +
        (index % 2 === 0 ? '' : 'bg-rosa')
      "
    >
      <b-col cols="1">{{ index + 1 }}</b-col>
      <b-col cols="6">
        <b-row>
          <b-col>
            {{ producto.producto }}
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            {{ producto.cantidad }} x {{ formatPrice(producto.precio) }}
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">{{
        formatPrice(producto.precio * producto.cantidad)
      }}</b-col>
      <b-col cols="2" class="text-right">
        <b-button @click="eliminarProducto(producto.codigo)" variant="danger"
          ><b-icon icon="trash"></b-icon
        ></b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const { formatPrice } = require("@/assets/functions/formaters.js");
const { resumenCarro } = require("@/assets/functions/calculosFinancieros");
// const _ = require("lodash");

export default {
  props: ["productos"],
  watch: {
    productos: function () {
      this.resumen = resumenCarro(this.productos);
    },
  },
  data() {
    return {
      resumen: {
        total: 0,
        bultos: 0,
      },
    };
  },
  methods: {
    formatPrice,
    eliminarProducto(codigo) {
      this.$emit("eliminar", codigo);
      // this.productos = _.remove(this.productos, {
      //   codigo: codigo,
      // });
      // console.log(this.productos);
      // this.$emit("productos", this.productos);
    },
  },
};
</script>

<style>
</style>