<template>
    <FomularioLogin></FomularioLogin>
</template>

<script>

import FomularioLogin from '@/components/Login.vue';
export default {
    components: {
        FomularioLogin
    }
}
</script>