import ProductoDetalle from '@/components/ProductoDetalle.vue';
<template>
    <ProductoDetalle :id="1"></ProductoDetalle>
</template>

<script>
import ProductoDetalle from '@/components/ProductoDetalle.vue';

export default {
    components:{
        ProductoDetalle
    }
}
</script>