const moment = require('moment')
const _ = require('lodash')
const axios = require('@/assets/functions/axiosClient')
const { formatPrice } = require('@/assets/functions/formaters')
const estados = {
    Reservado: 2,
    Pagado: 3,
    Recibido: 4,
    Anulado: -1,
    Solicitado: 1
}

const sucursales = {
    casaMatriz: 1,
    feria: 2
}

module.exports = class Venta {
    constructor() {
        this.cliente = "";
        this.direccion = "";
        this.estadoId = 2;
        this.medioPagoId = 1;
        this.sucursalId = 1;
        this.fechaCompra = moment().format("yyyy-MM-DDTHH:mm");
        this.id = null;
        this.telefono = null;
        this.productos = [];
    }

    async getPriceDelivery() {
        let costoDelivery = 0;
        if (this.direccion) {
            await axios.get("/public/integraciones/google/bussiness/sexduceme/deliverys-cost/" + this.direccion)
                .then(response => {
                    if (response.status === 200) {
                        costoDelivery = response.data.costo
                    }
                }).catch(() => {
                    costoDelivery = -1;
                })
        }
        return costoDelivery
    }

    agregarProducto(producto) {
        const search = _.find(this.productos, {
            codigo: producto.codigo,
        });
        if (search) {
            search.cantidad = Number(producto.cantidad) + Number(search.cantidad);
        } else {
            this.productos.unshift(producto);
        }
        this.updateFecha();
    }

    eliminarProducto(codigo) {
        const indexItem = _.findIndex(this.productos, {
            codigo: codigo
        });
        this.productos.splice(indexItem, 1);
        this.updateFecha();
    }

    vaciarProductos() {
        this.productos = [];
        this.updateFecha();
    }

    updateFecha() {
        this.fechaCompra = moment().format("yyyy-MM-DDTHH:mm");
    }

    setEstadoId(estadoId) {
        this.estadoId = estadoId;
    }

    setEstado(modos) {
        if (modos.modoFeria === true || modos.modoMerma === true) {
            this.setEstadoId(estados.Recibido);
        } else {
            this.setEstadoId(estados.Reservado);
        }
    }

    setSucursalId(sucursalId) {
        this.sucursalId = sucursalId;
    }

    setSucursal(modos) {
        if (modos.modoFeria === true) {
            this.setSucursalId(sucursales.feria);
        } else {
            this.setSucursalId(sucursales.casaMatriz);
        }
    }

    setCliente(cliente) {
        if (typeof cliente === 'string') {
            this.cliente = cliente;
        } else if (typeof cliente === 'object') {
            const modos = cliente;
            if (modos.modoFeria === true) {
                this.cliente = 'FERIA'
            } else if (modos.modoMerma === true) {
                this.cliente = 'MERMA'
            }
        }
    }

    async getDetail() {
        let total = 0;
        let detalle = "Detalle de su pedido:\n\n";
        const costoDelivery = await this.getPriceDelivery();
        const metodoEntrega = this.direccion ? "Delivery" : "Retiro"

        this.productos.forEach((producto) => {
            detalle +=
                producto.cantidad +
                " " +
                producto.producto +
                ": " +
                formatPrice(producto.precio * producto.cantidad) +
                "\n";
            total += producto.cantidad * producto.precio;
        });

        detalle += "\nSubtotal: " + formatPrice(total);

        detalle +=
            "\n\nEntrega (" +
            metodoEntrega +
            "): " +
            formatPrice(costoDelivery) +
            "\n\nTotal a Pagar: " +
            formatPrice(total + costoDelivery);
        return detalle;
    }
}