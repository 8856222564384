<template>
    <div >
        Parámetro: {{parametro}}
    </div> 
</template>

<script>


export default {
    components:{
    },
    data(){
        return{
            parametro:"Sin Valor"
        }
    },
    created(){
        console.log("Parámetros: "+this.$route.params.param);
    }
}
</script>