import ProductoCarro from '@/components/ProductoCarro.vue';
<template>
    <div>
        <div v-if="!carro.length>0">
            <i class="fa fa-shopping-cart">Carrito Vacío</i>
        </div>

        <div v-else v-for="(producto,index) in carro" :key="producto.id" class="container">
            <!-- <ProductoCarro :producto='producto'></ProductoCarro> -->
                <div class="row row-cols-5 row-cols-md-5 enmarcar">   
                        <img :src="producto.imagen">
                        <div class="px-1">
                            {{producto.nombre}}
                        </div>
                        <div>
                            {{producto.cantidad}}
                        </div>
                        <div>
                            {{formatPrice(producto.precio)}}
                        </div>
                        <div>
                            {{formatPrice(producto.cantidad*producto.precio)}}
                        </div>
                        <button class="btn btn-danger" @click="quitarCarro(index)"><i class="fa fa-trash"></i></button>
                </div>
        </div>
        <div v-if="carro.length>0" class="row row-cols-1 row-cols-md-4 d-flex justify-content-end">
            <button class="btn btn-danger mr-3 btn-block" @click="vaciarCarro()" data-v-7b876761="">
                <i class="fa fa-trash"></i> 
                Vaciar Carro
            </button>
            <a class="item-botonera col-12 " :href="'https://wa.me/56997322024?text='+this.pedirCarro()" :target="this.esMovil() ? '':'blank'"  data-v-7b876761="">
                <button class="btn btn-wp btn-block" data-v-7b876761="">
                    <i class="fa fa-whatsapp d-flex-right whatsapp-icon"></i> 
                    Solicitar esta Compra ({{formatPrice(sumarCarro())}})
                </button>
            </a>
        </div>
    </div>
</template>

<script>

// import ProductoCarro from '@/components/ProductoCarro.vue'

export default {
    name: 'CarroCompra',
    components:{
        // ProductoCarro
    },
    methods: {
        formatPrice(price){
            // return price;
            let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
            return '$ ' + priceString.substring(0,priceString.length-2);
        },
        vaciarCarro(){
            this.carro=[];
            localStorage.setItem('carrito',JSON.stringify(this.carro));
        },
        pedirCarro(){
            let mensaje = "Me%20gustaría%20comprar%20los%20siguientes%20productos:%0A";
            this.carro.forEach(producto => {
                mensaje += "%0A"+producto.cantidad+"%20"+producto.nombre+"%20(cod:%20*"+producto.codigo+"*)";
            });
            return mensaje;
        },
        sumarCarro(){
            let suma = 0;
            this.carro.forEach(producto => {
                suma += producto.cantidad*producto.precio;
            });
            return suma;
        },
        quitarCarro(index){
            this.carro.splice(index,1)
            localStorage.setItem('carrito',JSON.stringify(this.carro));
        },
        esMovil(){
            return (
                (navigator.userAgent.match(/Android/i)) ||
                (navigator.userAgent.match(/webOS/i)) ||
                (navigator.userAgent.match(/iPhone/i)) ||
                (navigator.userAgent.match(/iPod/i)) ||
                (navigator.userAgent.match(/iPad/i)) ||
                (navigator.userAgent.match(/BlackBerry/i))
            );
        }
    },
    data(){
        return {
            carro:[]
        }
    },
     metaInfo(){
            return{
                title: "Carro de Compra - Sexdúceme"
            }
        },
    created(){
        this.carro = JSON.parse(localStorage.getItem('carrito'));
        if(this.carro===null)
            this.carro=[];
    }
}
</script>

<style>
    .img-small{
        height: auto;
        width: 100px;
    }
    tr td{
        vertical-align: middle!important;
    }
    
</style>