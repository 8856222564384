<template>
  <div class="home container">
  <!-- <img alt="logo" class="logo" src="@/assets/logo.png"> -->
    <!-- <div class="row my-1">
      <button @click="obtenerTodosProductos()" type="button" class="btn btn-outline-dark btn-block">Todos los Productos</button>
      <button @click="obtenerProductosCategoria(categoria.id)" v-for="categoria in categorias" :key="categoria.id" type="button" class="col btn btn-outline-secondary">{{categoria.nombre}}</button>
    </div> -->
    
    <Galeria :class="this.cargando?'invisble':'mostrar'" :productos="productos"></Galeria>
    <div v-if="this.cargando">
      <img src="https://media.tenor.com/images/49552aa72f8a494e7c4fdf3c5f69f3e4/tenor.gif" alt="cargando">
    </div>
    <div v-else-if="!this.productos.length>0">
      {{mensaje}}
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import Galeria from '@/components/Galeria.vue'
const axios = require('@/assets/functions/axiosClient.js');

export default {
  name: 'Tienda',
  components: {
    Galeria
  },
   metaInfo(){
            return{
                title: "Tienda de Sexdúceme"
            }
        },
  data(){
    return {
      productos:[],
      categorias:[],
      cargando: false,
      mensaje: ""
      }

  },
  methods:{
    limpiarTexto(texto){
      texto = texto.replace(/ |_/g,"");
      texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return texto.toUpperCase();
    },
    obtenerCategorias(){
      this.cargando = true;
      axios
        .get('/inventarios/categorias/principales')
        .then(response => {
          if(response.status===200){
            this.categorias = response.data.data;
            console.log("Entró al else con: "+this.$route.params.categoria!==null)
          }
          else if(response.status===204){
            this.mensaje="No hay categorías en el sistema";
          }
          
          })
          .catch(error=>{
            console.log(error);
          })
        .finally(()=>{
          this.cargando = false;
          console.log("Entró al else con: "+this.$route.params.categoria!==null)
          if(this.$route.params.categoria.length!==0){
            this.categorias.forEach(categoria => {
              if(this.limpiarTexto(categoria.nombre)==this.limpiarTexto(this.$route.params.categoria))
                this.obtenerProductosCategoria(categoria.id);
            });
          }
          else{
            this.obtenerTodosProductos();
          }
        }
        )
    },
    obtenerProductos(categoriaId){
      if(categoriaId===undefined)
        this.obtenerTodosProductos();
      else
        this.obtenerProductosCategoria(categoriaId);
    },
    obtenerProductosCategoria(categoriaId){
      this.productos=[];
      this.cargando=true;
      this.cargando = true;
      axios
        .get('/inventarios/productos/categorias/'+categoriaId)
        .then(response => {
          if(response.status===200){
            this.productos = response.data.data;
          }
          else if(response.status===204){
            this.mensaje="No hay productos que concuerden con la categoría indicada.";
          }
          
          })
          .catch(error=>{
            console.log(error);
          })
        .finally(()=>{
          this.cargando = false;
        }
        )
    },
    obtenerTodosProductos(){
      this.productos=[];
      this.cargando=true;
      this.cargando = true;
      axios
        .get('/inventarios/productos')
        .then(response => {
          if(response.status===200){
            this.productos = response.data.data;
          }
          else if(response.status===204){
            this.mensaje="No hay productos disponibles en la tienda.";
          }
          
          })
          .catch(error=>{
            console.log(error);
          })
        .finally(()=>{
          this.cargando = false;
        }
        )
    }

    // Fin Methods
    },
  created () {
    this.categorias = this.obtenerCategorias();
  },

  }
  
</script>

<style>
  .invisible{
    display: none;
  }
  .mostrar{
    display: block;
  }
</style>

