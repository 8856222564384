<template>
    <div class="container">
        <div class="row">
            <p class="fondo-rosa text-salto-linea text-justify p-5 mx-3">
                Somos Carol y Felipe, el 2014 iniciamos una pequeña aventura capacitándonos en el mundo de los productos para adultos, con la intensión de compartir nuestros conocimientos centramos nuestra pyme en asesorar a nuestra clientela y así ayudarle a disfrutar plenamente su sexualidad. En el 2016 dimos un paso al costado para centrarnos en nuestros estudios formales, sin embargo, el 2020 hemos vuelto con energías y una imagen renovada, una aún mejor atención y con una pyme formalizada, pero siempre con el mismo objetivo: empoderar a cada persona y pareja de su propia sexualidad.
            </p>
        </div>
    </div>
</template>

<script>
export default {
     metaInfo(){
            return{
                title: "El Equipo de Sexdúceme"
            }
        }
}
</script>