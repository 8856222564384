<template>
    <div class="container">
        <h1 class="col-12 my-3">Mantenedor de Productos:</h1>
        <!-- DATOS DEL PRODUCTO -->
        <div class="row">
            <h2 class="col-12 text-left">Datos del Producto:</h2>
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Código Producto:</span>
                    </div>
                    <input @input="producto.codigo=limpiarCodigo(producto.codigo)" @blur="buscarCodigo()" type="text" v-model="producto.codigo" class="form-control">
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Nombre Producto:</span>
                    </div>
                    <input type="text" v-model="producto.nombre" class="form-control">
                </div>
                
            </div>
            <div class="col-md-6">
                <div class="input-group h-100 pb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Descripción<br>{{this.producto.descripcion.length}}</span>
                    </div>
                    <textarea v-model="producto.descripcion" class="form-control"></textarea>
                </div>
            </div>

            <!-- PRECIOS -->
            <div class="col-12 col-md-6">
                <div class="input-group my-1 col-12 px-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default">$</span>
                    </div>
                    <input type="text" v-model="producto.precio" placeholder="Precio Normal" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                </div>
                <div class="input-group my-1 col-12 px-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default">%</span>
                    </div>
                    <input type="text" v-model="producto.descuento" placeholder="Porcentaje de Descuento" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                </div>
                <div class="input-group my-1 col-12 px-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default">$</span>
                    </div>
                    <input type="text" :value="aplicarDescuento(producto.precio,producto.descuento)" placeholder="Precio con Descuento Aplicado" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="input-group h-100 pb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Detalles</span>
                    </div>
                    <textarea v-model="producto.detalles" class="form-control"></textarea>
                </div>
            </div>
        </div>

<!-- ACCORDION -->
<div id="accordion" class="my-5">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          Agregar Imágenes y Categorías
        </button>
      </h5>
    </div>
<!-- IMÁGENES -->
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
          <!-- IMÁGENES -->
        <div class="row my-1">
            <h2 class="col-12 text-left">Imágenes:</h2>
            <div class="d-flex flex-row col-12">
                <input v-model="this.imagen.url" type="text" placeholder="URL" class="form-control col-10">
                <button @click="agregarImagen()" class="btn btn-success col-2"><i class="fa fa-plus-circle"></i></button>
            </div>
            <div class="row">
                <div class="col-md-2 col-4 my-1" v-for="(imagenFor,index) in this.producto.imagenes" :key="index">
                    <div>
                        <img class="col" :src="imagenFor.url">
                        <button @click="descartarImagen(index)" class="col btn btn-danger"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- CATEGORÍAS -->
        <div class="row">
            <h2 class="col-12 text-left ">Categorías:</h2>
            <div class="text-left custom-control custom-switch col-md-3" v-for="categoria in categorias" :key="categoria.id">
                <input v-model="categoria.seleccionado" type="checkbox" class="custom-control-input" :id="'customSwitch'+categoria.id">
                <label class="custom-control-label" :for="'customSwitch'+categoria.id">{{categoria.nombre}}</label>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
        
<!-- BOTONERA -->
        <div class="d-flex flex-row-reverse pb-5 mt-2">
            <button class="btn btn-success mx-1" @click="guardarProducto()">{{producto.id===null? 'Guardar':'Editar'}}</button>
            <button class="btn btn-danger mx-1" @click="limpiar()">Limpiar</button>
        </div>
    </div>
</template>

<script>
const _ = require('lodash');
const axios = require('@/assets/functions/axiosClient.js');
export default {

    name: "MantenedorProducto",
    watch:{
        producto: function() {
            this.asignarCategorias();
        },
        productoCode: function(newValue) {
            this.limpiar();
            this.producto.codigo = newValue;
            this.buscarCodigo();
        }
    },
    methods:{
        limpiarCodigo(codigo){
            return codigo.replace(/[^\w\s]/gi, '')
        },
        deseleccionarCategorias(){
            this.categorias.forEach(categoria => {
                categoria.seleccionado=false;
            });
        },
        buscarCodigo(){
            if(this.producto.codigo.length>0){
                axios.get('/inventarios/productos/codigo/'+this.producto.codigo)
                    .then(response => {
                        if(response.status===200){
                            this.producto=response.data.data;
                            this.asignarCategorias();
                        }
                            
                    })
                    .catch(error=>{
                        console.log(error);
                    })
                    .finally(()=>{
                    this.cargando = false;
                    }
                    )
            }
        },
        aplicarDescuento(precio,descuento){
            let precioDescuento = precio-(precio*(descuento/100));
            return precioDescuento-(precioDescuento%10);
        },
        descartarImagen(index){
            this.producto.imagenes.splice(index,1)
        },
        agregarImagen(){
            let auxImage ={};
            Object.assign(auxImage, this.imagen)
            this.producto.imagenes.push(auxImage);
            this.imagen.url = "";
        },
        asignarCategorias(){
            this.categorias.forEach(categoria => {
                this.categoria.seleccionado = false;
                this.producto.categorias.forEach(categoriaProducto => {
                    if(categoriaProducto.id===categoria.id){
                        categoria.seleccionado=true;
                        return;
                    }
                });
            });
        },
        setearCategorias(){
            this.producto.categorias = [];
            this.categorias.forEach(categoria => {
                if(categoria.seleccionado)
                    this.producto.categorias.push(categoria);
            });
        },
        guardarProducto(){
            this.setearCategorias();
            axios.post('/inventarios/productos',this.producto)
                .then(response => {
                    
                    if(response.status===201){
                        console.log('Guardado'+response);
                        this.limpiar();
                    }
                    else{
                        console.table(response);
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
                .finally(()=>{
                this.cargando = false;
                }
                )
        },
        limpiar(){
            this.producto={
                id:null,
                nombre : "",
                codigo : "",
                descripcion : "",
                detalles: "",
                descuento : 0,
                instagram : "",
                precio : "",
                activo:null,
                categorias : [],
                imagenes : []
            }
            this.deseleccionarCategorias();
        }
    },
    props:['productoCode'],
    data(){
        return{
            categorias:[],
            producto:{
                id:null,
                nombre : "",
                codigo : "",
                descripcion : "",
                detalles: "",
                descuento : 0,
                instagram : "",
                precio : "",
                categorias : [],
                imagenes : []
            },
            categoria:{
                id: null,
                nombre: "",
                imagen: "",
                descripcion: null
            },
            imagen:{
                id: null,
                nombre: "",
                url: "",
                descripcion: ""
            }            
        }
    },
    created(){
        if(this.productoCode){
            this.producto.codigo = this.productoCode
        }
      axios.get('/inventarios/categorias')
        .then(response => {
          this.cargando = true;
          this.categorias = _.sortBy(response.data.data,['nombre']);
          this.categorias.forEach(categoria => {
              categoria.seleccionado=false;
          });
          })
          .catch(error=>{
            console.log(error);
          })
        .finally(()=>{
          this.cargando = false;
        }
        )
    }
    
}
</script>